/* Base container */
.phoneInputCountry {
  width: 115px !important;
  position: relative !important;
  direction: ltr !important;
  z-index: 2 !important;
}

/* Flag and area code container */
.phoneInputCountry :global(.react-tel-input) {
  display: flex !important;
  flex-direction: row !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 12px !important;
  overflow: visible !important;
  background-color: white !important;
  position: relative !important;
  height: 48px !important;
  direction: ltr !important;
  outline: 0 !important;
  transition: border-color 0.2s, outline 0.2s !important;
  &:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
  }
  &:focus-within {
    border-color: #FF4B91 !important;
    border-width: 2px !important;
    outline: 1px solid #FF4B91 !important;
    outline-offset: -1px !important;
  }
}

/* Flag button */
.phoneInputCountry :global(.selected-flag) {
  background-color: white !important;
  padding: 0 6px !important;
  display: flex !important;
  align-items: center !important;
  border-right: 1px solid rgba(0, 0, 0, 0.23) !important;
  min-width: 34px !important;
  width: 48px !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 1 !important;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
  transition: border-color 0.2s !important;
}

/* Area code input */
.phoneInputCountry :global(.form-control) {
  width: 85% !important;
  height: 48px !important;
  border: none !important;
  font-size: 14px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  background-color: white !important;
  text-align: right !important;
  direction: ltr !important;
  border-top-right-radius: 12px !important;
  outline: none !important;
}

/* Flag size adjustment */
.phoneInputCountry :global(.flag) {
  transform: scale(0.95) !important;
  margin-right: 0 !important;
}

/* Arrow adjustment */
.phoneInputCountry :global(.selected-flag .arrow) {
  position: absolute !important;
  right: -1px !important;
  margin-left: 0 !important;
}

/* Dropdown positioning */
.phoneInputCountry :global(.country-list) {
  width: 280px !important;
  padding: 0 !important;
  left: -1px !important;
  right: auto !important;
  direction: ltr !important;
  margin-top: 2px !important;
  border-radius: 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
  max-height: 200px !important;
  overflow-y: auto !important;
  top: 100% !important;
  z-index: 99 !important;
}

/* Countries container */
.phoneInputCountry :global(.countries) {
  max-height: calc(200px - 40px) !important;
  overflow-y: auto !important;
  scrollbar-width: thin !important;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent !important;
}

/* Scrollbar styling */
.phoneInputCountry :global(.countries::-webkit-scrollbar) {
  width: 6px !important;
}

.phoneInputCountry :global(.countries::-webkit-scrollbar-track) {
  background: transparent !important;
}

.phoneInputCountry :global(.countries::-webkit-scrollbar-thumb) {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-radius: 3px !important;
}

/* Hide the Phone label */
.phoneInputCountry :global(.special-label) {
  display: none !important;
}

/* Force LTR in RTL context */
[dir="rtl"] .phoneInputCountry,
[dir="rtl"] .phoneInputCountry :global(*) {
  direction: ltr !important;
}

/* Search box */
.phoneInputCountry :global(.search) {
  padding: 8px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  direction: ltr !important;
}

.phoneInputCountry :global(.search-box) {
  width: calc(100% - 16px) !important;
  padding: 8px 12px !important;
  direction: ltr !important;
  text-align: left !important;
}

/* Adjust border color on hover */
.phoneInputCountry :global(.react-tel-input:hover .selected-flag) {
  border-right-color: rgba(0, 0, 0, 0.87) !important;
}

/* Adjust border color on focus */
.phoneInputCountry :global(.react-tel-input:focus-within .selected-flag) {
  border-right-color: #FF4B91 !important;
}